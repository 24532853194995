import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ReadMore from "../components/ReadMore"
import SectionLayout from "../components/SectionLayout"
import CardSection from "../components/CardSection"

const Treatments = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { category: { eq: "Leczone choroby i urazy" } }
          }
          sort: { fields: frontmatter___order }
        ) {
          edges {
            node {
              fields {
                slug
              }
              excerpt
              frontmatter {
                category
                title
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <SectionLayout sectionname="Zakres leczenia">
        {data.allMarkdownRemark.edges.map(({ node }) => {
          const { title } = node.frontmatter
          return (
            <CardSection
              key={node.fields.slug}
              sectionClass="py-10"
              divClass="p-10"
            >
              <div className="mt-auto">
                <div className="font-bold text-xl mb-2">{title}</div>
                <p className="text-sm text-gray-600 flex items-center"></p>
                <p>{node.excerpt}</p>
                <div className="pt-4 flex">
                  <ReadMore to={node.fields.slug} />
                </div>
              </div>
            </CardSection>
          )
        })}
      </SectionLayout>
    )}
  />
)

export default Treatments
