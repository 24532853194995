import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Consultations from "../components/Consultations"
import Hospitals from "../components/Hospitals"
import Treatments from "../components/Treatments"
import AboutMe from "../components/aboutme"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        isBasePath={true}
      >
        <Seo title={siteTitle} tags={data.site.siteMetadata.indexTags} />

        {/* <div className="container mx-auto"> */}
        <AboutMe />
        <Consultations />
        <Hospitals />
        <Treatments />
        {/* </div> */}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        indexTags
      }
    }
  }
`
