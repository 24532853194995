import React from "react"
import { Link } from "gatsby"

const ReadMore = ({ to }) => {
  return (
    <Link
      className="border border-gray-600 hover:bg-gray-400 py-1 px-4 rounded"
      to={to}
    >
      Czytaj dalej
    </Link>
  )
}

export default ReadMore
