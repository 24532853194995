import React from "react"

const CardSection = ({ children, slug, sectionClass, divClass }) => {
  return (
    <section
      key={slug}
      className={
        "sm:w-1/1 md:w-1/2 lg:w-1/3 xl:w-1/3 " +
        (sectionClass ? sectionClass : "")
      }
    >
      <div
        className={
          "flex flex-col pt-10 card h-full rounded overflow-hidden shadow-lg mx-4 " +
          (divClass ? divClass : "")
        }
      >
        {children}
      </div>
    </section>
  )
}

export default CardSection
