import React from "react"

const Tags = ({children}) => {
    return (
      <div>
        {children.map((tag) => (
          <span key={"tags"+tag} className="inline-block bg-gray-600 rounded-full mt-1 px-3 py-1 text-sm font-semibold text-gray-100 mr-2">{tag}</span>
        ))}
      </div>
    )
}

export default Tags