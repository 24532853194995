import React from "react"
import Image from "gatsby-image"
import SectionLayout from "../components/SectionLayout"
import Tags from "../components/Tags"
import ReadMore from "../components/ReadMore"
import CardSection from "../components/CardSection"
import BottomCardAddress from "../components/BottomCardAddress"

const GoogleMapInfo = ({ title, address }) => {
  let q = (title + " " + address).replace(/\r?\n/, ",").replace(/\s+/g, " ")
  let uri = "//google.com/maps/search/?api=1&query=" + encodeURIComponent(q)
  return (
    <a className="" href={uri} target="_blank " rel="noopener noreferrer">
      {address}
    </a>
  )
}

const AddressCardSection = ({ children, type }) => {
  return (
    <SectionLayout sectionname={type}>
      {children.edges.map(({ node }) => {
        const { title, address, tel, www, tags, thumbnail } = node.frontmatter
        return (
          <CardSection
            key={"CardSection" + node.fields.slug}
            slug={node.fields.slug}
          >
            <div className="px-6 py-2 text-gray-700 text-base">
              <Tags>{tags}</Tags>
              <div className="font-bold text-xl mb-2">{title}</div>
              <p className="text-sm text-gray-600 flex items-center">
                <GoogleMapInfo title={title} address={address} />
              </p>
              <p>{node.excerpt}</p>
              <div className="pt-4 flex">
                <ReadMore to={node.fields.slug} />
              </div>
            </div>
            <BottomCardAddress
              tel={tel.slice(0, 2)}
              address={address}
              www={www.slice(0, 1)}
              title={title}
            >
              <div className="self-end w-32 logos">
                <Image fluid={thumbnail.childImageSharp.fluid} alt="" />
              </div>
            </BottomCardAddress>
          </CardSection>
        )
      })}
    </SectionLayout>
  )
}

export default AddressCardSection
