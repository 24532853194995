import React from "react"
import Icon from "@mdi/react"
import { mdiOpenInNew } from "@mdi/js"

const LinkWithIcon = ({
  uri,
  children,
  className = "",
  showExternalIcon = true,
}) => {
  console.log("uri", uri)
  return (
    <a
      className={`hover:underline hover:text-blue-400 flex items-center ${className}`}
      href={uri}
      target="_blank "
      rel="noopener noreferrer"
    >
      {children || uri}
      {showExternalIcon && (
        <Icon
          className="mx-1 align-text-bottom"
          path={mdiOpenInNew}
          size="1rem"
        />
      )}
    </a>
  )
}

export default LinkWithIcon
