import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SectionLayout from "../components/SectionLayout"
import ReadMore from "../components/ReadMore"

function AboutMe() {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
        query {
        allMarkdownRemark(filter: {frontmatter: {category: {eq: "O Mnie"}}}) {
          edges {
            node {
                fields {
                    slug
                  }
                longexcerpt:excerpt(pruneLength:348, format: HTML)
                frontmatter {
                    category
                    title
                }
            }
          }
        }
      }
      
    `
  )

  return (
    <SectionLayout sectionname="O Mnie">
      {allMarkdownRemark.edges.map(({ node }) => {
        const { title } = node.frontmatter
        return (
          <section key={title}>
            <div className="flex flex-wrap px-2 sm:px-0">
              <div key={node.fields.slug}>
                <div dangerouslySetInnerHTML={{ __html: node.longexcerpt }}></div>
                <div className="pt-4 flex">
                  <ReadMore to={node.fields.slug} />
                </div>
              </div>
            </div>
          </section>
        )
      })}
    </SectionLayout>
  )
}

export default AboutMe
