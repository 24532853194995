import React from "react"
import { mdiPhoneInTalk, mdiNavigation, mdiWeb, mdiAt } from "@mdi/js"
import Icon from "@mdi/react"
import LinkWithIcon from "./LinkWithIcon"

const GoogleMapDir = ({ title, address, children, className = "" }) => {
  let q = (title + " " + address).replace(/\r?\n/, ",").replace(/\s+/g, " ")
  let uri = "//google.com/maps/dir/?api=1&destination=" + encodeURIComponent(q)
  return (
    <a
      className={className}
      href={uri}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

const navtypes = {
  szpital: "szpitala",
  przychodnia: "przychodni",
  klinika: "kliniki",
  gabinet: "gabinetu",
}

const BottomCardAddress = ({
  children,
  tel,
  title,
  address,
  www,
  email,
  extraClass,
  type, // szpital, przychodnia
}) => {
  const navType = navtypes[type] || "..."
  console.log("title", title, address)
  const classes = "flex px-6 py-2 flex-grow " + (extraClass ? extraClass : "")
  const childDivClasses = extraClass ? "" : "self-end flex-1"
  return (
    <div className={classes}>
      <div className={childDivClasses}>
        {tel.map((phoneNo) => {
          const toHref = "tel:" + phoneNo
          return (
            <p
              key={phoneNo}
              className="text-sm md:text-base text-gray-800 hover:text-green-900 items-center"
            >
              <a
                className="flex phone-link"
                href={toHref}
                rel="noopener noreferrer"
              >
                <Icon
                  className="mr-1"
                  title={phoneNo}
                  path={mdiPhoneInTalk}
                  size="1rem"
                />
                {phoneNo}
              </a>
            </p>
          )
        })}
        {www &&
          www.map((address, i) => {
            return (
              <p key={`${i}${address}`}>
                <LinkWithIcon uri={address}>
                  <Icon
                    className="mr-1"
                    title={address}
                    path={mdiWeb}
                    size="1rem"
                  />
                  {address}
                </LinkWithIcon>
              </p>
            )
          })}
        {email && (
          <p>
            <LinkWithIcon uri={`{mailto:${email}`} showExternalIcon={false}>
              <Icon className="mr-1" title={email} path={mdiAt} size="1rem" />
              {email}
            </LinkWithIcon>
          </p>
        )}
        <p className="text-sm md:text-base text-gray-800 flex items-center">
          <GoogleMapDir
            title={title}
            address={address}
            className="flex items-center justify-center hover:text-green-900"
          >
            <Icon
              className="mr-1 icon direction"
              path={mdiNavigation}
              size="1rem"
            />
            Nawiguj do {navType}
          </GoogleMapDir>
        </p>
      </div>
      {children}
    </div>
  )
}

export default BottomCardAddress
