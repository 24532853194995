import React from "react"
import { StaticQuery, graphql } from "gatsby"

import AddressCardSection from "../components/AddressCardSection"

const Consultations = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { category: { eq: "Konsultacje" } } }
          sort: { fields: frontmatter___order }
        ) {
          edges {
            node {
              fields {
                slug
              }
              excerpt
              frontmatter {
                address
                category
                tel
                tags
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 120) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                title
                www
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <AddressCardSection type="Konsultacje">
        {data.allMarkdownRemark}
      </AddressCardSection>
    )}
  />
)

export default Consultations
